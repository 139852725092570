module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row between elm-reveal reveal small-card-wrap">\r\n    ';
 smallCaseList.forEach((item) => { ;
__p += '\r\n    <div class="col-md-3 col-sm-6 col-xs-12 slideup small-card">\r\n        <a href="' +
((__t = ( item.link )) == null ? '' : __t) +
'" class="insight-block track-btn" data-track="' +
((__t = ( item.track )) == null ? '' : __t) +
'">\r\n            <div class="ib-img tilt-link bg-hover">\r\n                <div class="ib-img-inner" style="background-image: url(\'' +
((__t = ( item.bgUrl )) == null ? '' : __t) +
'\')"></div>\r\n            </div>\r\n            <div class="ib-content ext">\r\n                <b>' +
((__t = ( item.title )) == null ? '' : __t) +
'</b>\r\n                <div class="xsm-space"></div>\r\n                <h5 class="ib-title">\r\n                    <span class="ellipsis">' +
((__t = ( item.desc )) == null ? '' : __t) +
'</span>\r\n                </h5>\r\n            </div>\r\n        </a>\r\n    </div>\r\n    ';
 }) ;
__p += '\r\n</div>\r\n';

}
return __p
};
