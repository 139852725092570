const requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame

exports.requestAnimationFrame = requestAnimationFrame

function parallaxScroll () {
    const windowOffset = document.body.scrollTop

    let lastPosition = -1
    if (lastPosition === windowOffset) {
        requestAnimationFrame(parallaxScroll)
        return false
    } else {
        lastPosition = windowOffset
    }
    const parallaxElements = document.querySelectorAll('[data-parallax]')

    for (let i = 0, len = parallaxElements.length; i < len; i++) {
        let el = parallaxElements[i]
        const parent = el.parentNode

        const parentHeight = parent.getBoundingClientRect().height
        const parentOffset = parent.getBoundingClientRect().top + (parentHeight / 2)
        const finalOffset = (window.innerHeight / 2) - parentOffset
        const translate = finalOffset * el.getAttribute('data-parallax')

        if (el.getAttribute('data-no-mobile') && window.innerWidth < 768) {
            el.style.transform = 'translate3d(0,0,0)'
        } else {
            el.style.transform = `translate3d(0, ${translate.toFixed(1)}px, 0)`
        }
    }

    requestAnimationFrame(parallaxScroll)
}

parallaxScroll()
