import cookies from 'js-cookie'

$(() => {
    const $cookieBodyWrap = $('#cookie-body-wrap')

    if (cookies.get('cookie_accept') !== 'accept') {
        $cookieBodyWrap.fadeIn()
    }

    $cookieBodyWrap.find('#cookie-button-wrap').click(function () {
        cookies.set('cookie_accept', 'accept')
        $cookieBodyWrap.fadeOut()
    })
})
