import { TweenLite, Expo } from 'gsap'

class NavControl {
    constructor () {
        this.status = 'close'
        this.ntWidth = $('.nt-1').width()
        let ddTimer, adTimer

        $('.nav-trigger').bind('click', () => {
            this.navToggle()
        })

        $('#bd-link').click(() => {
            if (window.innerWidth <= 992) {
                return
            }
            $('#about-dropdown').removeClass('active')
            $('#business-dropdown').addClass('active')
            $('.sub-nav').addClass('active')
            $('#bd-link').addClass('active')
        })
        $('#bd-link').mouseenter(() => {
            if (window.innerWidth <= 992) {
                return
            }
            $('#about-dropdown').removeClass('active')
            $('#business-dropdown').addClass('active')
            $('.sub-nav').addClass('active')
            $('#bd-link').addClass('active')
        })

        $('#bd-link, #business-dropdown').mouseleave(() => {
            ddTimer = setTimeout(() => {
                if (window.innerWidth <= 992) {
                    return
                }
                $('#business-dropdown').removeClass('active')
                $('#bd-link').removeClass('active')
                $('.sub-nav').removeClass('active')
            }, 100)
        })

        $('#bd-link, #business-dropdown').mouseenter(() => {
            clearTimeout(ddTimer)
        })
        $('#ab-link').click(() => {
            $('#business-dropdown').removeClass('active')
            $('#about-dropdown').addClass('active')
            $('.sub-nav').addClass('active')
            $('#ab-link').addClass('active')
        })

        $('#ab-link').mouseenter(() => {
            $('#business-dropdown').removeClass('active')
            $('#about-dropdown').addClass('active')
            $('.sub-nav').addClass('active')
            $('#ab-link').addClass('active')
        })

        $('#ab-link, #about-dropdown').mouseleave(() => {
            adTimer = setTimeout(() => {
                $('#about-dropdown').removeClass('active')
                $('#ab-link').removeClass('active')
                $('.sub-nav').removeClass('active')
            }, 100)
        })

        $('#ab-link, #about-dropdown').mouseenter(() => {
            clearTimeout(adTimer)
        })

        $('.nav-back').click(() => {
            setTimeout(() => {
                $('#about-dropdown').removeClass('active')
                $('#ab-link').removeClass('active')
                $('#business-dropdown').removeClass('active')
                $('#bd-link').removeClass('active')
                $('.sub-nav').removeClass('active')
            }, 100)
        })
    }

    navToggle () {
        if (this.status === 'close') {
            this.status = 'animate'
            $('#main-nav').show()
            TweenLite.to($('.nt-1'), 0.4, {
                y: 5,
                ease: Expo.easeInOut
            })
            TweenLite.to($('#main-nav aside'), 0.4, {
                opacity: 1,
                delay: 0.4
            })
            TweenLite.to($('.nav-right-bg'), 1, {
                x: '0%',
                ease: Expo.easeInOut
            })
            TweenLite.to($('.nav-left-bg'), 1, {
                x: '0%',
                delay: 0.2,
                ease: Expo.easeInOut,
                onComplete: () => {
                    $('#main-nav nav').addClass('reveal')
                }
            })
            TweenLite.to($('.nt-2'), 0.4, {
                width: this.ntWidth,
                y: -5,
                ease: Expo.easeInOut,
                onComplete: () => {
                    $('header').addClass('no-bg')
                    TweenLite.to($('.nt-1'), 0.4, {
                        rotation: 45,
                        ease: Expo.easeInOut
                    })
                    TweenLite.to($('.nt-2'), 0.4, {
                        rotation: -45,
                        ease: Expo.easeInOut,
                        onComplete: () => {
                            this.status = 'open'
                        }
                    })
                }
            })
        }
        if (this.status === 'open') {
            this.status = 'animate'
            $('#main-nav nav').removeClass('reveal')
            TweenLite.to($('#main-nav aside'), 0.4, {
                opacity: 0
            })
            TweenLite.to($('.nt-1'), 0.4, {
                rotation: 0,
                ease: Expo.easeInOut
            })
            TweenLite.to($('.nav-right-bg'), 1, {
                x: '100%',
                delay: 0.2,
                ease: Expo.easeInOut,
                onComplete: () => {
                    $('#main-nav').hide()
                }
            })
            TweenLite.to($('.nav-left-bg'), 1, {
                x: '100%',
                ease: Expo.easeInOut,
                onComplete: () => {
                    $('header').removeClass('no-bg')
                }
            })
            TweenLite.to($('.nt-2'), 0.4, {
                rotation: 0,
                ease: Expo.easeInOut,
                onComplete: () => {
                    TweenLite.to($('.nt-1'), 0.4, {
                        y: 0,
                        ease: Expo.easeInOut
                    })
                    TweenLite.to($('.nt-2'), 0.4, {
                        y: 0,
                        width: 30,
                        ease: Expo.easeInOut,
                        onComplete: () => {
                            this.status = 'close'
                        }
                    })
                }
            })
        }
    }

    dropdownShow () {
        console.log('show')
    }

    dropdownHide () {
        console.log('hide')
    }
}

export default NavControl
