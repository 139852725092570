$(function () {
    const fixedElem = $('#fixed-bar-wrap')

    $(window).on('scroll', function (e) {
        const scrollTop = $(this).scrollTop()

        if (scrollTop < 400) {
            fixedElem.css({
                top: `900px`
            })
            return
        }
        if (scrollTop >= 600) {
            fixedElem.css({
                top: `${scrollTop + 300}px`
            })
        }
    })

    $(document).on('click', '#fixed_totop', function () {
        $('html, body').animate({ scrollTop: 0 }, 150)
    })

    $(document).on('click', '#share_twitter', function () {
        const url = encodeURIComponent(document.location.href)
        const text = encodeURIComponent('6e GROUP')
        const shareLink = `http://twitter.com/share?url=${url}&text=${text}`
        window.open(shareLink)
    })

    $(document).on('click', '#share_linkedin', function () {
        const url = encodeURIComponent(document.location.href)
        const title = encodeURIComponent('6e GROUP')
        const summary = encodeURIComponent('6e GROUP')
        const shareLink = `https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${summary}&source=LinkedIn`
        window.open(shareLink)
    })

    $(document).on('click', '#share_mail', function () {
        const url = encodeURIComponent(document.location.href)
        const shareLink = `mailto:?body=发现一个有趣的网站：${url}`
        window.open(shareLink)
    })
})
