module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<a href="' +
((__t = ( link )) == null ? '' : __t) +
'" class="news_card_wrap track-btn" data-track="' +
((__t = ( track )) == null ? '' : __t) +
'">\r\n    <div class="content-wrap ext">\r\n        <h4 class="desc ellipsis">' +
((__t = ( desc )) == null ? '' : __t) +
'</h4>\r\n        <p>' +
((__t = ( date )) == null ? '' : __t) +
'</p>\r\n    </div>\r\n    <div class="img-wrap bg-hover tilt-link" style="background-image: url(\'' +
((__t = ( bgUrl )) == null ? '' : __t) +
'\')"></div>\r\n</a>\r\n';

}
return __p
};
