import { TweenLite, Expo } from 'gsap'
import utils from '@js/utils'

(() => {
    let status = 'close'
    let search = utils.getURLParam('search')

    const $header = $('header')
    const $searchBodySec = $('.search-body-sec')
    const $searchWrap = $('.search-wrap')
    const $searchResultSec = $('.search-result-sec')
    const $searchInput = $searchWrap.find('input')

    if (search) {
        toggleSearch()
        $searchInput.val(search)
    }

    $('.nav-search, .search-btn').click(function () {
        toggleSearch()
    })

    $searchInput.keypress(function (e) {
        if (e.which !== 13 || !$(this).val()) {
            return
        }

        const search = encodeURIComponent($(this).val())
        location.href = `search.html?search=${search}`
    })

    function toggleSearch () {
        if (status === 'close') {
            status = 'open'
            $searchBodySec.show()
            $header.addClass('search-bg')
            $searchResultSec.addClass('open')
            TweenLite.to($searchWrap, 0.5, {
                height: search ? 'auto' : '100vh',
                ease: Expo.easeInOut,
                onComplete: () => {
                    $searchInput.focus()
                }
            })
        } else {
            status = 'close'
            $searchResultSec.removeClass('open')
            TweenLite.to($searchWrap, 0.5, {
                height: '0',
                ease: Expo.easeInOut,
                onComplete: () => {
                    $searchBodySec.hide()
                    $header.removeClass('search-bg')
                    $searchInput.val('')
                    search = ''
                }
            })
        }
    }
})()
