module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="detail-banner-wrap">\r\n    <div class="detail-banner-bg">\r\n        <div class="img-inner" style="background-image: url(\'' +
((__t = ( bannerUrl )) == null ? '' : __t) +
'\')"></div>\r\n    </div>\r\n</div>\r\n<div class="detail-title-wrap">\r\n    <h6>' +
((__t = ( campany )) == null ? '' : __t) +
'</h6>\r\n    <h2>' +
((__t = ( title )) == null ? '' : __t) +
'</h2>\r\n    <div class="sm-space"></div>\r\n    <p class="date">' +
((__t = ( date )) == null ? '' : __t) +
'</p>\r\n</div>\r\n<div class="detail-content-wrap">\r\n    ' +
((__t = ( htmlString )) == null ? '' : __t) +
'\r\n</div>';

}
return __p
};
