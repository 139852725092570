import TweenMax from 'gsap'

class CursorEffects {
    constructor () {
        this.initCursor()
        this.initHovers()
    }

    initCursor () {
        const { Back } = window
        this.outerCursor = document.querySelector('.circle-cursor--outer')
        this.innerCursor = document.querySelector('.circle-cursor--inner')
        this.outerCursorBox = this.outerCursor.getBoundingClientRect()
        this.outerCursorSpeed = 0
        this.easing = Back.easeOut.config(1.8)
        this.clientX = -100
        this.clientY = -100
        this.showCursor = false

        const unveilCursor = () => {
            TweenMax.set(this.innerCursor, {
                x: this.clientX,
                y: this.clientY
            })
            TweenMax.set(this.outerCursor, {
                x: this.clientX - this.outerCursorBox.width / 2,
                y: this.clientY - this.outerCursorBox.height / 2
            })
            setTimeout(() => {
                this.outerCursorSpeed = 0.4
            }, 100)
            this.showCursor = true
        }
        document.addEventListener('mousemove', unveilCursor)

        document.addEventListener('mousemove', e => {
            this.clientX = e.clientX
            this.clientY = e.clientY
        })

        const render = () => {
            TweenMax.set(this.innerCursor, {
                x: this.clientX,
                y: this.clientY
            })
            if (!this.isStuck) {
                TweenMax.to(this.outerCursor, this.outerCursorSpeed, {
                    x: this.clientX - this.outerCursorBox.width / 2,
                    y: this.clientY - this.outerCursorBox.height / 2
                })
            }
            if (this.showCursor) {
                document.removeEventListener('mousemove', unveilCursor)
            }
            requestAnimationFrame(render)
        }
        requestAnimationFrame(render)
    }

    initHovers () {
        const handleMouseEnter = () => {
            this.innerCursor.classList.add('ext-hover')
            this.outerCursor.classList.add('ext-hover')
        }

        const handleMouseLeave = () => {
            this.innerCursor.classList.remove('ext-hover')
            this.outerCursor.classList.remove('ext-hover')
        }

        $(document).on('mouseenter', '.ext', handleMouseEnter)
        $(document).on('mouseleave', '.ext', handleMouseLeave)

        const mainNavMouseEnter = () => {
            this.innerCursor.classList.add('sq-hover')
            this.outerCursor.classList.add('sq-hover')
        }

        const mainNavMouseLeave = () => {
            this.innerCursor.classList.remove('sq-hover')
            this.outerCursor.classList.remove('sq-hover')
        }

        $(document).on('mouseenter', '.bg-hover', mainNavMouseEnter)
        $(document).on('mouseleave', '.bg-hover', mainNavMouseLeave)
    }
}

export default CursorEffects
