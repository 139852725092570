$(window).on('load DOMContentLoaded resize', function () {
    let widthRatio // 设计稿宽度;

    const html = document.documentElement
    const windowWidth = window.innerWidth

    if (windowWidth <= 992) {
        widthRatio = (windowWidth < 768) ? 414 : 768
        html.style.fontSize = windowWidth / widthRatio * 100 + 'px'
    } else if (windowWidth > 992 && windowWidth <= 1440) {
        html.style.fontSize = windowWidth / 1440 * 100 + 'px'
    } else {
        html.style.fontSize = '100px'
    }
})
