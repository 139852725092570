(() => {
    $('.form-field input, .form-field textarea').val('')

    $(document).on('input', '.form-field.textfield-wrap input, .form-field.textarea-textfield-wrap textarea', function () {
        let val = $(this).val()

        if (val) {
            if (!$(this).hasClass('not-empty')) {
                $(this).addClass('not-empty')
            }
            return
        }

        $(this).removeClass('not-empty')
    })

    $(document).on('focus', '.form-field.textfield-wrap input, .form-field.textarea-textfield-wrap textarea', function () {
        $(this).parent().removeClass('error')
        setMessage()
    })

    $(document).on('click', '.form-field.select-textfield-wrap', function (e) {
        setMessage()
        if ((e.target.className.indexOf('select-textfield-item') > -1)) {
            return
        }
        $(this).find('input').addClass('is-touched')
        $(this).find('.select-textfield-cover').addClass('is-touched')
        $(this).find('.select-textfield-list').addClass('show')
        $(this).removeClass('error')
    })

    $(document).on('blur', '.form-field.select-textfield-wrap', function () {
        let $input = $(this).find('input')

        if (!$input.val()) {
            $(this).find('input').removeClass('is-touched')
        }
        $(this).find('.select-textfield-cover').removeClass('is-touched')
        $(this).find('.select-textfield-list').removeClass('show')
    })

    $(document).on('click', '.form-field.select-textfield-wrap .select-textfield-item', function () {
        let $selector = $(this).parents('.form-field.select-textfield-wrap')

        $(this).addClass('selected').siblings().removeClass('selected')
        $selector.find('.select-textfield-cover').removeClass('is-touched')
        $selector.find('.select-textfield-list').removeClass('show')
        $selector.find('input').val($(this).html())
    })

    $(document).on('change', '.form-field.upload-textfield-wrap input[type=file]', function (e) {
        $(this).parent().removeClass('error')
        if ($(this).val().split('\\').pop()) {
            $(this).addClass('not-empty')
            $(this).prev('.upload-textfield-cover').find('.upload-textfield-file').html($(this).val().split('\\').pop())
        } else {
            $(this).removeClass('not-empty')
            $(this).prev('.upload-textfield-cover').find('.upload-textfield-file').html('')
        }
    })
})()

function resetForm () {
    $('.form-field input, .form-field textarea').val('').removeClass('not-empty is-touched')
    $('.form-field .upload-textfield-file').html('')
    $('.form-field .select-textfield-item').removeClass('selected')
}

function setMessage ({ success = '', fail = '' } = {}) {
    $('.submit-msg-wrap').find('.success-msg').html(success)
    $('.submit-msg-wrap').find('.error-msg').html(fail)
}

export {
    resetForm,
    setMessage
}
