module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="sw-main-wrap">\r\n    <div class="banner-cover-wrap"></div>\r\n    <div class="banner-cover-opacity-wrap swiper-container sw-body-wrap ' +
((__t = ( fullbanner ? 'full' : '' )) == null ? '' : __t) +
'">\r\n        <div class="sw-bg-wrap"></div>\r\n        <div class="swiper-wrapper sw-wrap">\r\n            ';
 swiperData.forEach((item, index) => { ;
__p += '\r\n            <a class="swiper-slide sw-item track-btn" href="' +
((__t = ( item.link )) == null ? '' : __t) +
'" data-track="Feature ' +
((__t = ( item.track )) == null ? '' : __t) +
'">\r\n                <div data-parallax=\'-0.2\' class="sw-img-wrap ext" >\r\n                    <div class="img-inner" style="background-image: url(\'' +
((__t = ( item.bgUrl )) == null ? '' : __t) +
'\')"></div>\r\n                </div>\r\n                <h2 data-parallax=\'-0.2\' class="index-wrap white">' +
((__t = ( `0${index + 1}` )) == null ? '' : __t) +
'</h2>\r\n            </a>\r\n            ';
 }) ;
__p += '\r\n        </div>\r\n        <div class="swiper-pagination"></div>\r\n    </div>\r\n    <div class="banner-cover-opacity-wrap sw-thumb-wrap">\r\n        <div class="inner-content">\r\n            <div>\r\n                <div class="swiper-wrapper sw-wrap">\r\n                    ';
 swiperData.forEach((item, index) => { ;
__p += '\r\n                    <a data-bgcolor="' +
((__t = ( item.bgColor )) == null ? '' : __t) +
'" href="' +
((__t = ( item.link )) == null ? '' : __t) +
'" class="track-btn ext insight-block swiper-slide sw-item ' +
((__t = ( index === 0 ? 'show' : '' )) == null ? '' : __t) +
'" data-track="Feature ' +
((__t = ( item.track )) == null ? '' : __t) +
'">\r\n                        <h6>' +
((__t = ( item.title )) == null ? '' : __t) +
'</h6>\r\n                        <div class="xsm-space"></div>\r\n                        <h3 class="ib-title"><span class="ellipsis">' +
((__t = ( item.desc )) == null ? '' : __t) +
'</span></h3>\r\n                    </a>\r\n                    ';
 }) ;
__p += '\r\n                </div>\r\n            </div>\r\n        </div>\r\n    </div>\r\n</div>\r\n';

}
return __p
};
