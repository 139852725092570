import { TweenLite, Expo } from 'gsap'
import './banner_cover.scss'

export default () => {
    TweenLite.to($('.banner-cover-wrap'), 1, {
        x: 0,
        ease: Expo.easeInOut,
        delay: 0.5,
        onComplete: () => {
            TweenLite.to($('.banner-cover-wrap'), 1, {
                x: '100%',
                ease: Expo.easeInOut
            })
            $('.banner-cover-opacity-wrap').css({
                opacity: 1
            })
        }
    })
}
