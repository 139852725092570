import Swiper from 'swiper'

import compSwiper from './banner_swiper.ejs'
import './banner_swiper.scss'

import bannerCover from '@comp/banner_cover/banner_cover'

class Swipers {
    constructor (data, wrapperId, options, fullbanner = true) {
        this.instance = null
        this.thumb = null
        this.swiperData = data
        this.wrapperId = `#${wrapperId}`
        this.index = 0
        this.options = {
            direction: 'vertical',
            loop: true,
            speed: 1000,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            autoplay: {
                delay: 3000,
                disableOnInteraction: false
            },
            ...options
        }
        this.fullbanner = fullbanner
    }

    init () {
        const html = compSwiper({
            swiperData: this.swiperData,
            fullbanner: this.fullbanner
        })
        $(this.wrapperId).html(html)
        this.thumb = $('.sw-thumb-wrap .sw-item')
        $('.sw-main-wrap .sw-bg-wrap').css({
            'background': `#${this.thumb.eq(0).data('bgcolor')}`
        })
        this.instance = new Swiper('.sw-body-wrap', {
            ...this.options
        })
        this.instance.on('slideChange', () => {
            if (this.index === this.instance.realIndex) {
                return
            }
            this.index = this.instance.realIndex
            this.thumb.delay(500).fadeOut(200).delay(300).eq(this.index).fadeIn(200)
            $('.sw-main-wrap .sw-bg-wrap').css({
                'background': `#${this.thumb.eq(this.index).data('bgcolor')}`
            })
        })

        bannerCover()
    }
}

export default Swipers
