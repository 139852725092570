import utils from '@js/utils'
export function trackSection (sectionname) {
    var gtag = window.gtag

    console.log('section:' + sectionname)

    gtag('config', 'UA-125390000-1', {
        'page_title': sectionname,
        'page_path': window.location.pathname
    })
}

export function trackEvent (eventname, path) {
    var gtag = window.gtag

    var pathname = window.location.pathname === '/' ? 'index' : window.location.pathname

    path = path || pathname.replace('/', '').split('.')[0]

    console.log('label:' + eventname + ' _' + utils.firstUpperCase(path))

    gtag('event', 'click', {
        'event_category': 'clicktrack', 'event_label': eventname + ' _' + utils.firstUpperCase(path)
    })
}
