module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<a href="' +
((__t = ( link )) == null ? '' : __t) +
'" class="case-card insight-block track-btn" data-track="' +
((__t = ( track )) == null ? '' : __t) +
'">\r\n    <div class="ib-img tilt-link bg-hover">\r\n        <div class="ib-img-inner" style="background-image: url(\'' +
((__t = ( bgUrl )) == null ? '' : __t) +
'\')"></div>\r\n    </div>\r\n    <div class="ib-content ext">\r\n        <h4>\r\n            <b>' +
((__t = ( title )) == null ? '' : __t) +
'</b>\r\n        </h4>\r\n        ';
 if (desc) { ;
__p += '\r\n        <div class="xsm-space"></div>\r\n        <p class="ib-title">\r\n            <span class="ellipsis">' +
((__t = ( desc )) == null ? '' : __t) +
'</span>\r\n        </p>\r\n        ';
 } ;
__p += '\r\n    </div>\r\n</a>\r\n';

}
return __p
};
