module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<a href="' +
((__t = ( link )) == null ? '' : __t) +
'" class="insight-block track-btn" data-track="' +
((__t = ( track )) == null ? '' : __t) +
'">\r\n    <div class="ib-img tilt-link bg-hover">\r\n        <div class="ib-img-inner" style="background-image: url(\'' +
((__t = ( bgUrl )) == null ? '' : __t) +
'\')"></div>\r\n    </div>\r\n    <div class="ib-content ext">\r\n        <b>' +
((__t = ( title )) == null ? '' : __t) +
'</b>\r\n        <div class="xsm-space"></div>\r\n        <h5 class="ib-title">\r\n            <span class="ellipsis">' +
((__t = ( desc )) == null ? '' : __t) +
'</span>\r\n        </h5>\r\n    </div>\r\n</a>\r\n';

}
return __p
};
