import inView from 'in-view'

function init () {
    inView('.elm-reveal').on('enter', (el) => {
        $(el).addClass('reveal')
    })

    $(window).on('scroll', function (e) {
        if ($(this).scrollTop() >= 100) {
            $('header').addClass('mini')
            $('.header-bg').addClass('mini')
        } else {
            $('header').removeClass('mini')
            $('.header-bg').removeClass('mini')
        }
    })
}

export default {
    init
}
