import CursorEffects from './cursor'
import NavControl from '@comp/nav/nav'
import { trackEvent } from '@/scripts/ga'
import './flexible'
import './parallax'
import '@comp/search/search'
import '@comp/cookie/cookie'

import '@css/style.scss'

$(() => {
    /* eslint-disable no-new */
    new CursorEffects()
    new NavControl()

    const mac = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)

    if (mac) {
        $('body').addClass('isMac')
    }

    $(document).on('click', '.track-btn', function () {
        const track = $(this).data('track')
        const path = $(this).data('path')
        if (track) {
            trackEvent(track, path)
        }
    })
})
