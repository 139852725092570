/**
 * 获取url的参数值
 * @param {String} strParamName 参数名
 * @return 参数值
 */
function getURLParam (strParamName) {
    let result = null
    let tmp = []
    let items = window.location.search.substr(1).split('&')
    for (let i = 0, len = items.length; i < len; i++) {
        tmp = items[i].split('=')
        if (tmp[0] === strParamName) {
            result = decodeURIComponent(tmp[1])
            break
        }
    }
    return result
}

// 时间戳转换日期 (yyyy-MM-dd HH:mm:ss)
function formatDateTime (timeValue) {
    const date = new Date(timeValue)
    const y = date.getFullYear()
    let m = date.getMonth() + 1
    let d = date.getDate()
    let h = date.getHours()
    let minute = date.getMinutes()
    let second = date.getSeconds()
    m = m < 10 ? ('0' + m) : m
    d = d < 10 ? ('0' + d) : d
    h = h < 10 ? ('0' + h) : h
    minute = minute < 10 ? ('0' + minute) : minute
    second = second < 10 ? ('0' + second) : second
    return y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + second
};

// 判断传入日期是否为昨天
function isYestday (timeValue) {
    const date = (new Date()) // 当前时间
    const today = new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime() // 今天凌晨
    const yestday = new Date(today - 24 * 3600 * 1000).getTime()
    return timeValue < today && yestday <= timeValue
};

// 判断传入日期是否属于今年
function isYear (timeValue) {
    const takeNewYear = formatDateTime(new Date()).substr(0, 4) // 当前时间的年份
    const takeTimeValue = formatDateTime(timeValue).substr(0, 4) // 传入时间的年份
    return takeTimeValue === takeNewYear
}

// 60000 1分钟
// 3600000 1小时
// 86400000 24小时
// 对传入时间进行时间转换
function timeChange (timeValue) {
    const timeNew = Date.parse(new Date()) // 当前时间
    const timeDiffer = timeNew - timeValue // 与当前时间误差

    if (timeDiffer <= 60000) { // 一分钟内
        return 'Just Now'
    } else if (timeDiffer > 60000 && timeDiffer < 3600000) { // 1小时内
        return Math.floor(timeDiffer / 60000) + ' minutes ago'
    } else if (timeDiffer >= 3600000 && timeDiffer < 86400000 && isYestday(timeValue) === false) { // 今日
        return formatDateTime(timeValue).substr(11, 5)
    } else if (timeDiffer > 3600000 && isYestday(timeValue) === true) { // 昨天
        return 'Yesterday ' + formatDateTime(timeValue).substr(11, 5)
    } else if (timeDiffer > 86400000 && isYestday(timeValue) === false && isYear(timeValue) === true) { // 今年
        return formatDateTime(timeValue).substr(5, 11)
    } else if (timeDiffer > 86400000 && isYestday(timeValue) === false && isYear(timeValue) === false) { // 不属于今年
        return formatDateTime(timeValue).substr(0, 10)
    }
    return ''
}

function isEmail (val) {
    return /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/.test(val)
}

function isWebsite (val) {
    return /(https?|ftp):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?/.test(val)
}

function isPhone (val) {
    return /^((\d{11})|^((\d{7,8})|(\d{4}|\d{3})-(\d{7,8})|(\d{4}|\d{3})-(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1})|(\d{7,8})-(\d{4}|\d{3}|\d{2}|\d{1}))$)$/.test(val)
}

function firstUpperCase (str) {
    return str.replace(/\b(\w)(\w*)/g, function ($0, $1, $2) {
        return $1.toUpperCase() + $2.toLowerCase()
    })
}

export default {
    getURLParam,
    timeChange,
    isEmail,
    isWebsite,
    isPhone,
    firstUpperCase
}
